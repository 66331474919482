import { v4 as uuidv4 } from 'uuid';
// import ProjectImg from '../images/projectImg.png';
import UTrackerImg from '../images/utracker.png';
import AmpImg from '../images/Amp.png';
import initGram from '../images/initGram.png';
import MyCopa from '../images/MyCopa.png';
import PunotesImg from '../images/punotes.JPG';
import KailabaImg from '../images/kailabaWeb.png';
import CWDImg from '../images/cwdhcj.png';
import initBlog from '../images/initBlog.png';
import initDevelops from '../images/initDevelops.png'
import initWeatherImg from '../images/Weatherapp.png';

const projects = [
  {
    id: uuidv4(),
    name: 'Kailaba Official',
    desc: 'An official website of Kailaba Brand. Blog post ,Book Summary and many informative things.',
    img: KailabaImg,
    type:"web",
    source:"https://kailaba.com/"
  },
  {
    id: uuidv4(),
    name: 'Advance Music Player',
    desc: 'Music player site and have home ui similar to spotify and all the basic features of instagram-MERN stack',
    img: AmpImg,
    type:"web",
    source:"https://youtu.be/kw1M9uiT4_c"
  },
  {
    id: uuidv4(),
    name: 'Covid Tracker',
    img: UTrackerImg,
    desc: 'An application to track corona data from Nepal. I developed this mobile app using react native.',
    type: 'mobile',
    source:"https://github.com/SujanSB/CoronaDetails"
  },
  {
    id: uuidv4(),
    name: 'InitDevelops',
    img: initDevelops,
    desc: 'Full-stack website for a startup-like company using modern technologies & includes admin dashboard.',
    type: 'web',
    source:"https://initdevelops.herokuapp.com/"
  },
  {
    id: uuidv4(),
    name: 'initGram -A MERN app',
    desc: 'It includes all the major functions of Instagram:- like, comment, upload, update, delete, search user, andmany more... ',
    img: initGram,
    type:"web",
    source:"https://github.com/SujanSB/InstaClone-MERN"
  },
 
  
  {
    id: uuidv4(),
    name: 'PU notes',
    desc: "Using this app you can get all the details of your currently running semester ,for Pokhara university's Student",
    img: PunotesImg,
    type: 'both',
    source:""

  },
  {
    id: uuidv4(),
    name: 'pi Tech',
    desc: "Fully Static site for newly starting company, created using only HTML,CSS and JS.(One of my best frontend design)",
    img: CWDImg,
    type: 'web',
    source:"https://sujansb.github.io/CWD/"

  },
  {
    id: uuidv4(),
    name: 'initWeather',
    desc: 'Using openweather API ,with the help of reactjs i have created Weather application ,Responsive design',
    img: initWeatherImg,
    type: 'web',
    source:"https://initweather.netlify.app/"
  },
  {
    id: uuidv4(),
    name: 'initBlog || Your own Creation',
    desc: "A MERN stack web application where user can post and view other's blog and many more.",
    img: initBlog,
    type: 'web',
    source:"https://initblogs.herokuapp.com/about"
  },
  {
    id: uuidv4(),
    name: 'My CoPa',
    desc: "Simple MERN app, User can update their profile, choose their favorite colors, and can upload them for future use.",
    img: MyCopa,
    type: 'web',
    source:"https://mycopa.herokuapp.com/"
  },
 
];

export default projects;

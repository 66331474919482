import React, { useState } from "react";
import styled from "styled-components";

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import "!style-loader!css-loader!react-toastify/dist/ReactToastify.css";

import toast, { Toaster } from 'react-hot-toast';

import axios from 'axios';

const FormStyle = styled.form`
  width: 100%;
  .form-group {
    width: 100%;
    margin-bottom: 2rem;
  }
  label {
    font-size: 1.8rem;
  }
  input,
  textarea {
    width: 100%;
    font-size: 2rem;
    padding: 1.2rem;
    color: var(--gray-1);
    background-color: var(--deep-dark);
    outline: none;
    border: none;
    border-radius: 8px;
    margin-top: 1rem;
  }
  textarea {
    min-height: 250px;
    resize: vertical;
  }
  button[type="submit"] {
    background-color: var(--gray-1);
    color: var(--black);
    font-size: 2rem;
    display: inline-block;
    outline: none;
    border: none;
    padding: 1rem 4rem;
    border-radius: 8px;
    cursor: pointer;
  }
`;


export default function ContactForm() {
  const [subject, setSubject] = useState(null);
  const [info, setInfo] = useState(null);
  const [warn, setWarn] = useState(null);
  const [message, setMessage] = useState(null);
  const [user_email, setUser_email] = useState(null);
  const [loading, setLoading] = useState(false);

  const  sendEmail=async(e)=> {
    e.preventDefault();
    if (subject === "" || subject === null) {
      setInfo("Better if you provide your name");
    }

    const pattern =
      /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const result = pattern.test(user_email);
    if (result === true) {
      if (message === "" || message === null) {
        setWarn("Message can't be empty");

        return;
      } else {
        try {
          setLoading(true);
          const { data } = await axios.post(`https://portfoliocontact-api.herokuapp.com/api/email`, {
            email:user_email,
            subject,
            message,
          });
          setLoading(false);
          toast.success(data.message);
          setSubject('')
          setUser_email('')
          setMessage('')

        } catch (err) {
          setLoading(false);
          toast.error(
            err.response && err.response.data.message
              ? err.response.data.message
              : err.message
          );
        }
          
      }
    } else {
      setWarn("Please enter the valid email address");
    }
  }
  return (
    <>
<Toaster
  position="top-right"
  reverseOrder={false}
/>      <form
        onSubmit={sendEmail}
        style={{ width: "100%", marginBottom: "2rem" }}
      >
        <div className="form-group">
          {warn ? (
            <div
              style={{
                margin: "20px 0px ",
                color: "black",
                padding: 5,
                fontSize: 10,
                textAlign: "center",
                backgroundColor: "#cbf186",
              }}
            >
              {info}
            </div>
          ) : null}
          <label style={{ fontSize: "1.8rem" }}>Subject</label>
          <input
            type="text"
            name="name"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            style={{
              width: "100%",
              fontSize: "2rem",
              padding: "1.2rem",
              color: "gray",
              backgroundColor: " var(--deep-dark)",
              outline: "none",
              border: "none",
              borderRadius: 8,
              marginTop: "1rem",
            }}
          ></input>
        </div>
        <div className="form-group">
          <label style={{ fontSize: "1.8rem" }}>Your Email *</label>
          <input
            type="email"
            name="user_email"
            value={user_email}
            onChange={(e) => setUser_email(e.target.value)}
            style={{
              width: "100%",
              fontSize: "2rem",
              padding: "1.2rem",
              color: "gray",
              backgroundColor: " var(--deep-dark)",
              outline: "none",
              border: "none",
              borderRadius: 8,
              marginTop: "1rem",
            }}
          ></input>
        </div>
        {warn ? (
          <div
            style={{
              margin: "20px 0px ",
              color: "black",
              padding: 10,
              fontSize: 20,
              textAlign: "center",
              backgroundColor: "#f39a35",
            }}
          >
            {warn}
          </div>
        ) : null}
        <div className="form-group">
          <label style={{ fontSize: "1.8rem" }}>Your Message *</label>
          <textarea
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="4"
            style={{
              width: "100%",
              fontSize: "2rem",
              padding: "1.2rem",
              color: "gray",
              backgroundColor: " var(--deep-dark)",
              outline: "none",
              border: "none",
              borderRadius: 8,
              marginTop: "1rem",
            }}
          ></textarea>
        </div>
        <input
          type="submit"
          value={loading ? 'Sending...' : 'Submit'}
          style={{
            marginTop: 30,
            backgroundColor: "white",
            color: "black",
            fontSize: "2rem",
            display: "inline-block",
            outline: "none",
            border: "none",
            padding: "1rem 4rem",
            borderRadius: "8px",
            cursor: "pointer",
          }}
        ></input>
      </form>
    </>
  );
}

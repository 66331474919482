import React from "react";
import AboutInfoItem from "../components/AboutInfoItem";

import styled from "styled-components";
import PText from "../components/PText";

const AchPageStyles = styled.div`
  padding: 10rem 0 10rem 0;

  
  .ach__info__items {
    margin-top: 15rem;
  }
  .ach__info__item {
    margin-bottom: 6rem;
    h2 {
      background-color: teal;
      background-position: center;
      border-radius: 0.5rem;
      padding: 1rem;
      font-size: 1.6rem;
      margin: 3rem;
    }
  }
  .ach__info__heading {
    font-size: 3.6rem;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 768px) {
    .container {
      padding: 2rem;
    }
  }
`;

function Achievements() {
  return (
    <AchPageStyles>
      <div className="container">
        <div className="ach__info__items">
          <div className="ach__info__item">
            <h1 className="ach__info__heading">Educational Achievements</h1>

            <div style={{ display: "flex", alignItems: "center" }}>
              <h2>HSEB Scholarship (+2)</h2>
              <h2>PU Scholarship (B.E)</h2>
            </div>
          </div>
          <div className="ach__info__item">
            <h1 className="ach__info__heading">Certificates</h1>
            <div style={{ display: "flex", alignItems: "center" }}>
              <h2>On the field of machine learning and data sceince</h2>
              <h2>On the field of web-devlopment</h2>
            </div>
          </div>
        </div>
      </div>
    </AchPageStyles>
  );
}

export default Achievements;

const testimonials = [
  {
    id: 1,
    name: 'Prerit Bhandari',
    title: 'Software Developer',
    org: 'Computer Engineer',
    desc: 'Sujan is a very enthusiastic learner and most beautiful things about him is his commitment. I have collabed with him on many projects.',
  },
  {
    id: 2,
    name: 'Shiva P.Gyawali',
    title: 'FOUNDER',
    org: 'Kailaba',
    desc: 'Really hard-working person. Deliver the work as promised. Planning to work more with this person.',
  },
];
export default testimonials;

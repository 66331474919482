import React from 'react';
import { MdBubbleChart, MdCode, MdPhonelinkSetup } from 'react-icons/md';
import styled from 'styled-components';
import SectionTitle from './SectionTitle';
import ServicesSectionItem from './ServicesSectionItem';

const ServicesItemsStyles = styled.div`
  padding: 10rem 0;
  .services__allItems {
    display: flex;
    gap: 5rem;
    justify-content: space-between;
    margin-top: 5rem;
    border-radius: 2rem;
  }
  @media only screen and (max-width: 768px) {
    padding: 3rem 0;

    .container {
      padding: 1.8rem;
    }
    .services__allItems {
      flex-direction: column;
      max-width: 350px;
      margin: 0 auto;
      margin-top: 3rem;
      gap: 4rem;
    }
  }
`;

export default function ServicesSection() {
  return (
    <ServicesItemsStyles>
      <div className="container">
        <SectionTitle subheading="What i can do for you" heading="Services" />
        <div className="services__allItems">
          <ServicesSectionItem
            icon={<MdCode />}
            title="Web Dev"
            desc="I can develop the websites. I love to make highly interactive website with MERN stack."
          />
          <ServicesSectionItem
            icon={<MdPhonelinkSetup />}
            title="App Dev"
            desc="Having an experience of 1 years react-native, I can say Building native-app is a fun."
          />
          <ServicesSectionItem
            icon={<MdBubbleChart />}
            title="AI solution "
            desc="I can integrate AI approaches and analysis on several sort of datas, as per requirement."
          />
        </div>
      </div>
    </ServicesItemsStyles>
  );
}

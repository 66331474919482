import React, { useState } from 'react';
import styled from "styled-components";

import { Document, Page } from 'react-pdf';
import PText from "../components/PText";

import RecomKba from '../assets/images/RecomKba.png'



const SSNotesPagesStyles = styled.div`
  padding: 10rem 0 10rem 0;

  .top-section {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    background-color:rgba(0,255,0,0.1);
    padding:1rem;

  }
  .left {
    flex: 4;
  }
  .right {
    flex: 2;
  }
  .about__subheading {
    font-size: 2.2rem;
    margin-bottom: 2rem;
    span {
      background-color: var(--deep-dark);
      padding: 0.5rem;
      border-radius: 8px;
    }
  }
  .ssnotes__heading {
    font-size: 3.6rem;
    margin-bottom: 3rem;
  }

  .kailaba__info {
    margin-bottom: 1rem;
    padding:1rem;
    .link_button{
      margin-top:1em;
      padding:.5em 2em;
      outline:none;
      border-radius:5;
    }
    .para {
      max-width: 100%;
    }
  }
 
  .right {
    img {
      border: 2px solid var(--gray-1);
    }
  }
  .my_notes-section{
    width:100%;
    margin-top:2rem;
     .para {
      width: 100%;
    }
  }
  .my_note-info{
    font-size:2rem;
  }
  .all-subjects{
    margin-top:2rem;
    display:flex;
    align-items: center;
    gap: 2rem;
    justify-content: flex-start;
    flex-wrap:wrap;
    .each-subjects{
      flex: 1 1 calc(50% - 1rem);
      padding:1rem 2rem;
      background-color: var(--deep-dark);
      margin-top:2rem;
      box-sizing: border-box;
      display:flex;
      justify-content:space-between;
      align-items:center;
      a{
      font-size:1em;
      }
    }
  }
  
  
  @media only screen and (max-width: 768px) {
    .container {
      padding: 2rem;
    }
    padding: 10rem 0;
    .top-section {
      flex-direction: column;
      gap: 1rem;
    }
    .about__subheading {
      font-size: 1.8rem;
    }
    .ssnotes__heading {
      font-size: 2.8rem;
      text-align:center;
    }
    .kailaba__info {
      margin-bottom: 0rem;
      .link_button{
        padding:.2em 1em;      
        }
    }
    .sh-info{
      margin-top:1em;
      }

    .my_note-info{
      font-size:1.5em;
    }
}
`;

let subject_lists=[
  {
    id:1,
    name:"Discrete Structure",
    link:""
  },
  {
    id:1,
    name:"Computer Architecture",
    link:""
  },
  {
    id:1,
    name:"Artificial Intelligence",
    link:""
  },
  {
    id:1,
    name:"Computer Graphics",
    link:""
  }
]

export default function SSNotes() {
  
  return (
    <>
    <SSNotesPagesStyles>
      <div className="container">
          <h2 className="ssnotes__heading">Sujan Sir Ko Notes:</h2>
          <div className="top-section">
              <div className="left">
                <div className="kailaba__info">
                  <PText>
                  For All Engineering Notes and Resources, I prefer to visit Kailaba.com
                    <br />
                    
                  </PText>
                    <button btnText="Visit Kailaba" btnLink="#" className="link_button">
                        <a
                          href="https://kailaba.com/engineering-notes-kailaba/"
                          style={{color:"black",fontSize:"1rem"}}
                          target='_blank'
                        >
                        Visit Kailaba
                        </a>

                  </button>
                </div>
              </div>
              <div className="right">
                <img src={RecomKba} alt="me" />
              </div>
            </div>

          <div className='my_notes-section'>
            <h3 className='my_note-info'>I am currently working as a Part Time <span style={{color:"cyan"}}>" Visiting Faculty "</span> Lecturer at Vedas College (TU).</h3>
                <PText > <p className="sh-info">Here are the subjects I teach.</p></PText>
              <p style={{color:"green",border:"1px solid cyan",margin:"1em",textAlign:"center",padding:"0.5em",fontSize:"2em"}}>Will update the link, Soon !!!</p>
              <div className='all-subjects'>
                  {subject_lists.map((item, index) => (
                      <div className='each-subjects' key={index}>
                          <PText>{item.name} </PText>
                          <a href={item.link} >View</a>
                      </div>
                    ))}
              </div>
          </div>


        </div>

    </SSNotesPagesStyles>
    </>
  );
}
import React from "react";
import styled from "styled-components";

import TypeWriter from "typewriter-effect";

const HeroStyles = styled.div`
  .hero {
    height: 90vh;
    width: 100%;
    display: flex;
    align-items: center;
    background-color:"#1e2fe0";
    justify-content: center;
    position: relative;
    font-family: "Poppins", sans-serif;
  }
  .hero__heading {
    font-size: 3rem;
    font-family: "Poppins", sans-serif !important;
    position: relative;
    span {
      display: inline-block;
      width: 100%;
      
    }
   margin-bottom:5rem
  }
  .hero__name {
    font-size: 7rem;
    text-align: center;
    color: cyan;
  }
  @media only screen and (max-width: 768px) {
    .hero {
      min-height: 600px;
    }
    .hero__heading {
      padding: 20px;
      font-size: 1.8rem;
      
    }
    .hero__name {
      margin-top: 30px;
      font-size: 4rem;
      height:12rem;
    }
  }
`;

export default function HeroSection() {
  return (
    <HeroStyles>
      <div className="hero">
        <div className="container">
          <h1 className="hero__heading">
            <span>Hey ,</span>
            <span>You found me.</span>
            <span>Welcome !!!</span>
         
</h1>
            <h1 className="hero__name">
           
              <TypeWriter
              options={{
                strings:["I'm Sujan Sharma",'A Comp. Engineer','A ML Engineer','A Lecturer.',],
                autoStart:true,
                loop:true
              }}
               
              />
           
            </h1> 
        </div>
      </div>
    </HeroStyles>
  );
}
